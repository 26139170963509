<template>
    <div class="cta-caja cta-caja--lila" @click="clicking">
      <div class="cta-caja__cover">
        <img
          class="cta-caja__cover-desk"
          src="https://images.hellobb.net/centro-de-actividades-bright-starts-desktop.jpg"
          alt=""
        />
        <img
          class="cta-caja__cover-mobile"
          src="https://images.hellobb.net/centro-de-actividades-bright-starts-responsive.png"
          alt=""
        />
      </div>
      <div class="cta-caja__content">
        <h4 class="cta-caja__title">Centro de actividades Bright Starts</h4>
        <div class="cta-caja__description">
          <p>Diversión pura para tu bebé.</p>
        </div>
        <div class="cta-caja__actions">
          <loading-button v-if="loading" :class="'button-loading--sm'" />
          <button
            class="button button--accent button--sm button--ico"
            @click.stop="addProduct('Constructor - Banner Horitzontal')"
            :disabled="added"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
      <modal-dialog
          :show="showProductAddedDialog"
          @close="showProductAddedDialog = false"
          target-class="modal__card--mini"
      >
        <product-added-to-list/>
      </modal-dialog>
    </div>
  </template>
  
  <script>
  import Product from "./Mixins/Product";
  import LoadingButton from "../../../components/LoadingButton";
  import ModalDialog from "../../../components/ui/ModalDialog";
  import ProductAddedToList from "../../../components/ProductAddedToList";
  
  export default {
    name: "CtaActividadesBrightStarts",
    mixins: [Product],
    components: {
      LoadingButton,
      ModalDialog,
      ProductAddedToList
    },
    computed: {
      buttonText() {
        return this.added ? "Añadido" : "Añadir a mi lista";
      },
    },
    created() {
      this.product = {
        id: 42484,
        "brand-slug": "bright-starts",
        name: "Centro de actividades Bright Starts",
        retailerName: "HelloBB",
        productRetailers: [{"name": "HelloBB"}]
      };
    },
    methods: {
      clicking()
      {
        mixpanel.track("Click element constructor", {
          "Name": "Centro de actividades Bright Starts",
          "Section": "Los libros para bebés más adorables",
          "Section number": 1,
          "Type": "Banner - Horizontal",
          "Content": "Ecommerce"
        });
        this.browseProduct();
      }
    }
  };
  </script>